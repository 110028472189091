import Navbar 				from "./components/Navbar";

import Store 				from "./pages/Store";
import ProductDetail 		from './pages/ProductDetail';

// import Pricing 			from "./pages/Pricing";
import Home 				from "./pages/Home";
// import About 				from "./pages/About";
import MyCourses 			from "./pages/MyCourses";
import Lessons 				from "./pages/Lessons";

import Login 				from "./pages/Login";
import PasswordReset		from "./pages/PasswordReset";
// import Test 				from "./pages/Test";
import Success 				from "./pages/Success";
import Cancel 				from "./pages/Cancel";
import SignUp 				from "./pages/SignUp";

import React from 'react';
import { Route, Routes } 	from "react-router-dom"

import { AuthProvider } 	from './context/AuthContext';


function App() {

  	return (
		<>
			<AuthProvider>

				<Navbar />
			
				<div className="container">
					<Routes>
						<Route path="/" 					element={<Home />} />
						<Route path="/courses" 				element={<Store />} />
						<Route path="/product/:id" 			element={<ProductDetail />} />
						<Route path="/Lessons" 				element={<Lessons />} />
						<Route path="/MyCourses" 			element={<MyCourses />} />
						<Route path="/login" 				element={<Login />} />
						<Route path="/passwordReset"		element={<PasswordReset />} />
						<Route path="/cancel" 				element={<Cancel />} />
						<Route path="/success" 				element={<Success />} />
						<Route path="/signup" 				element={<SignUp />} />
					</Routes>
				</div>

				{/* <h1> COMING SOON </h1> */}
			
			</AuthProvider>
		</>
  	)
}

export default App


// import { useAuthState } from "react-firebase-hooks/auth";
import {
	GoogleAuthProvider,
	signInWithPopup,
	getAuth, 
	createUserWithEmailAndPassword
} from "firebase/auth";
import { auth } 					from '../utils/firebase';

import React, {useState} from 'react';
import {
	useLocation,
	useNavigate,
}  									from 'react-router-dom';

import { FcGoogle } 				from "react-icons/fc";



export default function SignUp() {

	const navigate 							= useNavigate();
	const location 							= useLocation();
	const state 							= location.state || {};

	console.log(state);

	const [
		credentials, 
		setCredentials
	] 										= useState({email: "", password: ""});
	const googleProvider 					= new GoogleAuthProvider();



	const signUp							= async ()=>{

												const auth 			= getAuth();

												const userCredential = await createUserWithEmailAndPassword(auth, credentials.email, credentials.password)
												
												// Signed up 
												const user = userCredential.user;

												// In case of pending order:
												if( state.retryOrderConfig ){

													console.log('navigate to...');
												

													navigate(
														'/product/'+state.retryOrderConfig.priceId, 
														{ 
															state: { 
																course: 			state.retryOrderConfig.course,
																retryOrderConfig:	state.retryOrderConfig
															} });

													return;
												}
												
													// ...
												// })
												// .catch((error) => {
												// 	const errorCode = error.code;
												// 	const errorMessage = error.message;
												// 	// ..
												// 	console.log(error);
												// });
											};

	

	const GoogleLogin 						= async () => {
												try {
													const result = await signInWithPopup(auth,googleProvider);

													// In case of pending order:
													if( state.retryOrderConfig ){

														navigate(
															'/product/'+state.retryOrderConfig.priceId, 
															{ 
																state: { 
																	course: 			state.retryOrderConfig.course,
																	retryOrderConfig:	state.retryOrderConfig
																} });

														return;
													}

													navigate('/');
												}
												catch(error){
													console.log(error);
												}
											}



  	return (

		<div
			style						={{ 
											marginTop:					'4rem',
											display: 					'flex',
											gap: 						'2rem',
											flexDirection:				'column',
											alignItems: 				'center',			// verticale as
											// justifyContent:			'center'			// horizontale as
										}}
		>
		
			<div
				style						={{ 
												display: 					'flex',
												alignItems: 				'flex-start',		// verticale as
												justifyContent:				'space-around',		// horizontale as
												padding:					20,
												color:						'var(--secondary-color)'
											}}
			>

				<button 
					style						= {{ 
													display: 					'flex',
													justifyContent:				'space-between',

													height: 					'50px',
													lineHeight: 				'50px', 
													boxSizing: 					'border-box',
													paddingBottom:				20,
													paddingLeft:				12,
													paddingRight:				16,

													color:						'var(--secondary-color)',
													backgroundColor:			'var(--primary-color)',

													border:						'2px solid var(--secondary-color)',
													borderRadius: 				'8px',
													fontSize: 					'1rem',
													cursor: 					'pointer'
												}}

					onClick						= {GoogleLogin}
				>

					<div
						style						={{
														padding:				2,
														paddingRight:			8,
													
													}}
					>
						<FcGoogle />
					</div>
					<div>
						Sign in with Google
					</div>
				</button>
			</div>



			<div className				="login-container">
			
				<input

					className				="login-input"
					value					={credentials.email}
					type					="text"
					placeholder				='Please enter your e-mail address'
					onChange				={event => setCredentials({email: event.target.value, password: credentials.password})}
				/>
				<input
					className				="login-input"
					value					={credentials.password}
					type					="password"
					placeholder				='enter your password'
					onChange				={event => setCredentials({email: credentials.email, password: event.target.value})}
				/>
				<button
					className				={`login-button ${!(/^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(credentials.email)) ? 'disabled' : ''}`}
					disabled				={ !(/^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(credentials.email)) }
					onClick					={()=>{
												signUp();
											}}
				>
					sign up
				</button>
			</div>
		</div>
	)
}

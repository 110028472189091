
import 
	React, 
	{ 
		useEffect, 
		useState 
	} 								from 'react';

import { 
	GoogleAuthProvider, 
	signInWithPopup,
	getAuth, 
	createUserWithEmailAndPassword, 
	signInWithEmailAndPassword,
	sendPasswordResetEmail, 
	setPersistence, 
	browserLocalPersistence
}									from 'firebase/auth';

import { useAuthState } 			from "react-firebase-hooks/auth";
import { auth } 					from '../utils/firebase';
import {
	BrowserRouter, 
	Route, 
	Routes, 
	useLocation,
	useNavigate,
	Link
} 									from 'react-router-dom';

// - tbv Material UI (gebruik ik nu niet meer)
// import Button from '@mui/material/Button';

// [ ] icons implementeren 
import { FcGoogle } 				from "react-icons/fc";
// import { AiFillFacebook } from "react-icons/ai";




export default function Login() {

	const navigate 							= useNavigate();
	const location 							= useLocation();
	const state 							= location.state || {};

	console.log(state);

	const [user, loading] 					= useAuthState(auth);
	const googleProvider 					= new GoogleAuthProvider();

	// console.log(googleProvider);
	const [credentials, setCredentials] = useState({email: "", password: ""});

	// const [email, setEmail] = useState('');
	// const [message, setMessage] = useState('');
	// const [error, setError] = useState('');


	const GoogleLogin = async () => {

		try {
	
			const result = await signInWithPopup(auth,googleProvider);
			// console.log(result.user, finishOrder, '/product/'+priceId);

			// In case of pending order:
			if( state.retryOrderConfig ){

				navigate(
					'/product/'+state.retryOrderConfig.priceId, 
					{ 
						state: { 
							course: 			state.retryOrderConfig.course,
							retryOrderConfig:	state.retryOrderConfig
						} });

				return;
			}

			// Standard redirect
			navigate('/');
		}
		catch(error){
			console.log(error);
		}
	}


	
	const loginWithEmailAndPassword		= async ()=>{
		
		const auth = getAuth();

		try {

			await setPersistence(auth, browserLocalPersistence)


			const result = await signInWithEmailAndPassword(auth, credentials.email, credentials.password);

			// In case of pending order:
			if( state.retryOrderConfig ){

				navigate(
					'/product/'+state.retryOrderConfig.priceId, 
					{ 
						state: { 
							course: 			state.retryOrderConfig.course,
							retryOrderConfig:	state.retryOrderConfig
						} });

				return;
			}

			return result;
		}
		catch(error){

			console.log(error);
		}
	}



	// const handlePasswordReset = async (e) => {
	// 	e.preventDefault();
	// 	setMessage('');
	// 	setError('');

	// 	try {
	// 		await sendPasswordResetEmail(auth, email);
	// 		setMessage('E-mail voor wachtwoordherstel is verzonden.');
	// 	} catch (error) {
	// 		setError('Er is iets misgegaan. Controleer het e-mailadres en probeer het opnieuw.');
	// 		console.error(error);
	// 	}
	// };
	


		
	// doe iets als de gebruiker ingelogd is
	// auth.onAuthStateChanged( (user) => {
	// 	if(user){
	// 		console.log('logged in');
	// 	}
	// })


	useEffect(() => {
		if (user) {
			navigate('/');
		} else {
			console.log("login");
		}
	}, [user]);

	
	return (
		// <div className="shadow-xl mt-32 p-10 text-gray-700 rounded-lg">
		// 	<h2 className="text-3xl font-medium py-4">Join today</h2>

		// 	<div className="flex flex-col gap-4">
		// 		<button 
		// 			className="text-white bg-gray-700 p-4 w-full font-medium rounded-lg flex align-middle gap-4"
		// 			onClick={GoogleLogin}
		// 		> 
		// 			<FcGoogle className="text-2xl"/>
		// 			Sign in with Google
		// 		</button>
		// 		{/* <button>Sign in with Facebook</button> */}
		// 	</div>
		// </div>
		<div>
			<h3 
				style={{
					marginTop:				'10vh'
				}}
			>
				{/* Login */}
			</h3>

			<div
				style={{ 
					display: 					'flex',
					alignItems: 				'flex-start',		// verticale as
					justifyContent:				'space-around',		// horizontale as
					padding:					20,
					// color: 						'#950101',
					color:						'var(--secondary-color)',
					// backgroundColor: 		'#950101'
				}}
			>

				<button 
					style={{ 
						display: 					'flex',
						justifyContent:				'space-between',

						height: 					'50px',
						lineHeight: 				'50px', 
    					boxSizing: 					'border-box',
						paddingBottom:				20,
						paddingLeft:				12,
						paddingRight:				16,
						
						// color: 						'#950101',
						color:						'var(--secondary-color)',
						// backgroundColor: 			'#FFF',
						backgroundColor:			'var(--primary-color)',

						// border: 					'2px solid #950101', 
						border:						'2px solid var(--secondary-color)',
						borderRadius: 				'8px',
						// border: 					'none',
						// borderRadius:				10,

						fontSize: 					'1rem',
						cursor: 					'pointer'
					}}
					onClick={GoogleLogin}
				>

					<div
						style={{ 
							// display: 					'flex',
							// justifyContent:				'space-around',
							// height: 					'50px',
							// lineHeight: 				'50px', 
							// boxSizing: 					'border-box',
							padding:				2,
							paddingRight:			8,
						
						}}
					>
						<FcGoogle />
					</div>
					<div>
						Login with Google
					</div>
				</button>
			</div>

			<div
			
				style={{ 
					
					marginTop:					'4rem',

					display: 					'flex',
					gap: 						'2rem',
					flexDirection:				'column',
					alignItems: 				'center',			// verticale as
					// justifyContent:			'center'			// horizontale as
				}}
			>
				
				
				{/* <input
					style={{
						width:	'200px'
					}}

					value={credentials.email}
					type="text"
					placeholder='Please enter your e-mail address'
					onChange={event => setCredentials({email: event.target.value, password: credentials.password})}
				/>
				<input
					style={{
						width:	'200px'
					}}
					
					value={credentials.password}
					type="password"
					placeholder='enter your password'
					onChange={event => setCredentials({email: credentials.email, password: event.target.value})}
				/>

				{ /* [ ]] verplaatsen nar login * / }
				<button
					
					disabled={ !(/^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(credentials.email)) }
					onClick={()=>{
					// if (credentials.password === "let-me-in")
					// 	history.push("/secret");
						loginWithEmailAndPassword();
					}}
				>
					login
				</button> */}

				 <div className="login-container">
					{/* <h2 className="login-header">Login</h2> */}

					<input
						className="login-input"
						value={credentials.email}
						type="email"
						placeholder="Please enter your e-mail address"
						onChange={event => setCredentials({ ...credentials, email: event.target.value })}
					/>

					<input
						className="login-input"
						value={credentials.password}
						type="password"
						placeholder="Enter your password"
						onChange={event => setCredentials({ ...credentials, password: event.target.value })}
					/>

					<button
						className={`login-button ${!(/^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(credentials.email)) ? 'disabled' : ''}`}
						disabled={ !(/^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(credentials.email)) }
						onClick={loginWithEmailAndPassword}
					>
						Login
					</button>

					<p 
						
						onClick = { ()=> {navigate( "/passwordReset")}}

						style = {{
							// color: 						'inherit',
							color: 						'var(--quaternary-color)',
							textDecoration: 			'none',
							cursor: 					'pointer'
						}}
					>
						Forgot password? Get a new one here
					</p>
				</div>

				<div
						style = {{
							// marginTop:					'1rem'
						}}
				>
						<p 
						
							onClick = { ()=> {navigate( "/signup", { state: state })}}

							style = {{
								// color: 						'inherit',
								color: 						'var(--quaternary-color)',
								textDecoration: 			'none',
								cursor: 					'pointer'
							}}
						>
							If you don't have an account yet: sign up here
						</p>
				</div>

			</div>
		</div>
	);
}
/***




	TODO:


		- ik haal nu alleen de courses vd gebruiker op

		- deze lijst bevat alleen de course id's
		- Dus GEEN plaatjes van de course oid

		- course metaData haal ik apart op
		- die zou ik zowel voor de store als hier moeten gebruiken, ...dus 
		... dit is niet de plek om die op te halen 
		
		- of ik moet het n voor het gemak dubbel doen, ...
		... anders moet ik een dataStore op gaan zetten!



*/
import 
	React, { 
		useState, 
		useEffect 
	} 								from 'react';

import {
	useNavigate
} 									from 'react-router-dom';

import { 
	getAuth
} 									from "firebase/auth";

import MyCourseList 				from "../components/MyCourseList";
import { ClipLoader } 				from 'react-spinners';



export default function MyCourses() {

	const navigate 							= useNavigate();

	const [myCourses, setMyCourses] 		= useState([]);
	const [pageLoading, setPageLoading] 	= useState(true);

	useEffect(
		() => {

			const fetchMyCourses = async () => {

				try{
				
					const auth 					= getAuth();
					const idToken 				= await auth.currentUser.getIdToken();


					// Stuur een HTTP-verzoek naar de Firebase Cloud Function
					const response = await fetch(

						'https://getcoursesforuser-zso6kje7mq-uc.a.run.app', 
					
						{
							method: 'GET',
							headers: {
								Authorization: `Bearer ${idToken}`,
							},
						}
					);


					if (response.ok) {
					
						// Parse het antwoord en stel myCourses in met de ontvangen gegevens
						const data = await response.json();

						if( data.length > 0 ){
						
							setMyCourses(data[0].courses);
						}
						else{
							// [ ] todo: melding indien geen courses (of redirect?)
						}
					} 
					else {
						console.error('Fout bij het ophalen van cursussen:', response.statusText);
					}
				}
				catch (error) {
					console.error('1: Fout bij het ophalen van cursussen:', error);
				}

				setPageLoading(false);
			};


			// Roep de functie aan bij het laden van de pagina
			fetchMyCourses();
		}, 
		[]			// Lege afhankelijkheidsarray zorgt ervoor dat deze effect slechts eenmaal wordt uitgevoerd bij het mounten van de component
	); 



  	return (

		<div
			style = {{ 
				display: 					'flex',
				flexDirection: 				'column',

				// gap: 						'2rem',
				// alignItems: 				'flex-start',		// verticale as
				// justifyContent:				'space-around'		// horizontale as
			}}
		>
			{ pageLoading ? (
				<div className		= "loading-indicator">
				<br></br>
				<ClipLoader 
					// color="#36D7B7" 
					color			= 'var(--tertiary-color)'
					loading			= {pageLoading} 
					size			= {50} 
				/>
				</div>
			) : (


				
				
				<div
					style = {{ 
						// display: 		'flex',
						paddingTop:			'4rem',
						paddingLeft:		'4rem',
						paddingRight:		'4rem',
					}}
				>

					{
						myCourses && myCourses.length > 0 
						
						? 
					
						(

							<MyCourseList
								courses = { myCourses }
							>
							</MyCourseList>
						) 
						
						: 
						
						(
							<div>
								<p 
									
									// onClick = { ()=> {navigate( "/store")}}
									onClick = { ()=> {navigate( "/courses")}}			// courses = store

									style = {{
										color: 						'var(--quaternary-color)',
										textDecoration: 			'none',
										cursor: 					'pointer'
									}}
								>
									You don't have any courses yet. Go to the courses
								</p>
							</div>
						)
					}

					
				</div>

			)}
		</div>
	)
}

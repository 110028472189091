import ReactPlayer from 'react-player';			// https://www.npmjs.com/package/react-player




export default function VideoPlayer( props ) {
  	return (
		<div>
			
			<ReactPlayer 
	
				// url			='https://firebasestorage.googleapis.com/v0/b/planner-cf7ba.appspot.com/o/videos%2Ffilmpje.mp4?alt=media&token=9df11010-7d82-4bb8-9651-1dda641a68a1'
				url				= {props.url}
				controls 		= {true}

				// [ ] nog toepassen (okt 24)
				// light		= "https://img.youtube.com/vi/VIDEO_ID/maxresdefault.jpg" // URL van de thumbnail
			/>
		</div>
		
	)
}
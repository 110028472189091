import React, { useState } 			from 'react';
import { sendPasswordResetEmail } 	from 'firebase/auth';
import { auth } 					from '../utils/firebase';



const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('E-mail voor wachtwoordherstel is verzonden.');
    } catch (error) {
      setError('Er is iets misgegaan. Controleer het e-mailadres en probeer het opnieuw.');
      console.error(error);
    }
  };

  return (
    <div>
      <h2>Wachtwoord resetten</h2>
      <form onSubmit={handlePasswordReset}>
        <input
          type="email"
          placeholder="Voer je e-mailadres in"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button type="submit">Reset Wachtwoord</button>
      </form>
      {message && <p>{message}</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default PasswordReset;

import 
	React, 
	{ 
		useEffect, 
		useState, 
		useRef 
	} 								from 'react';

import 
{ 
	useParams, 
	useLocation,
	useNavigate 
} 									from 'react-router-dom';

import 
{ 
	getFunctions, 
	httpsCallable 
} 									from "firebase/functions";

import { app, auth, db } 			from '../utils/firebase';
import { useAuthState } 			from "react-firebase-hooks/auth";
import { collection, getDocs } 		from 'firebase/firestore';

import { ClipLoader } 				from 'react-spinners';



export default function ProductDetail() {

	// const { id } 						= useParams();  // Haal de id op uit de URL
	// const product 						= products.find(p => p.id === parseInt(id));  // Vind het product met de juiste id
	
	const functions 						= getFunctions(app, "us-central1");
	const [user, loading] 					= useAuthState(auth);

	const [checkoutLoading, setCheckoutLoading] 	= useState(false);

	const location 							= useLocation();
	const navigate 							= useNavigate();
	
	/*** 
		- we gebruiken state om de juiste course te bepalen
		- en voor een eventuele redirect na login/signup


		(okt 24)

			[/] komt de gebruik nu niet in een loop als ie 'terug' navigeert vanuit Stripe Checkout sessie?!
				- lijkt er wel op
				[/] ik kan er ook voor kiezen om gerbuiker alleen terug te laten gaan naar deze pagina (met juiste product) ...
					... maar zonder ook gelijk door te sturen naar Stripe checkout
			[/] netter maken:
				[/] vars mbt redirect naar apart object
			[/] redirect vanaf login 
				[/] na Google signin
				[/] na login met password+email

			[ ] redirect vanaf signup

				[x] sowieso nog link naar signup toevoegen aan login page
				[/] na Google signin

				[ ] na signup met password+email
					[ ] nog testen

	*/
	const state 							= location.state || {};
	const course							= state.course;
	const retryOrderConfig					= state.retryOrderConfig;

	// const selectPriceRef 					= useRef(null);
	const [selectedPriceIndex, setSelectedPriceIndex] = useState(0);



	const buyProduct = async (priceIndex) => {

		console.log(priceIndex, selectedPriceIndex);

		// priceIndex 					= priceIndex || selectPriceRef.current.value;
		priceIndex 					= priceIndex || selectedPriceIndex;

		console.log(priceIndex);

		// const priceIndex 			= selectPriceRef.current.value;
		const price					= course.prices[priceIndex];
		const priceId				= course.priceIds[priceIndex];
		const currency				= price[0];


		// Check of gebruiker al een account heeft
		if( ! user ){

			navigate(

				`/login`,

				/***
					object toevoegen voor afmaken bestelling 
				*/
				{ state: { 
					retryOrderConfig: { 
						priceId: 		priceId,
						priceIndex: 	priceIndex,
						course: 		course
					} 
				} }
			);

			return;
		}


		let paymentMethodTypes		= ['card'];
		if( currency == '$' ){
			// ..
		}
		else if( currency == '€' ){
			paymentMethodTypes.push( 'ideal' );
		}

		// Door naar Stripe checkout
		try {

			setCheckoutLoading( true );

			const checkout 						= httpsCallable( functions, 'checkout' );

			checkout( 
				{
					items: 						[
													{
														"id": 			priceId,
														"quantity": 	1
													}
												],
					paymentMethodTypes:			paymentMethodTypes
				}
			)
			.then((response) => {

				setCheckoutLoading( false );

				return JSON.parse(response.data);
			})
			.then((response) => {
			
				if(response.url) {
					window.location.assign(response.url); // Forwarding user to Stripe
				}
			});
		}
		catch(error){
			console.log(error);
		}
	}



	// if( retryOrderConfig ){
	// 	/***
	// 		(okt 24)
	// 			[ ] werkt nu maar gaat eigenlijk niet zoals het hoort: 
	// 				- pagina is nog niet klaar met renderen en blijft deze nu in een loop aanroepen
	// 			[ ] voor nu gaat gebruiker gewoon terug naar deze pagina, zonder redirect naar Stripe checkout
	// 	*/
	// 	buyProduct( retryOrderConfig.priceIndex );
	// }



	/***************************************************************************
	
		HTML
	
	****************************************************************************/
	if (!course) {

		return <h2> Course not found </h2>;
	}



	return (

		<div>
			
			<div className		= "loading-indicator">
			<br></br>
			<ClipLoader
				color			= 'var(--tertiary-color)'
				loading			= {checkoutLoading} 
				size			= {50} 
			/>
			</div>



			{/* 
				header 
			*/}
			<div
				style 			= {{ 
									marginBottom:		'4rem'
								}}
			>
				<h1>
					{course.name}
				</h1>
			</div>



			{/* 
				product container 
			*/}
			<div
				className="product-container"
				// style = {{ 
				// 	display: 			'flex',
				// 	flexDirection: 		'row',
				// 	justifyContent: 	'center',    // Centreert de containers horizontaal
				// 	alignItems: 		'flex-start',    // Zorgt dat de containers bovenaan worden uitgelijnd
				// 	width: 				'100%',               // Zorgt ervoor dat de container de volledige breedte benut
				// 	gap: 				'2rem',                 // Ruimte tussen de left container en buy container
				// 	// Media query voor kleinere schermen
				// 	'@media (max-width: 768px)': {
				// 		flexDirection: 		'column', 	// Op kleine schermen stapelen de containers
				// 		alignItems: 		'center',    // Zorgt dat ze in het midden staan op kleine schermen
				// 		backgroundColor: 'pink'
				// 	},
				// 	// [ ] test border
				// 	border: '1px solid #00FF00',
				// }}
			>

				{/*

					left container
				*/}
				<div
					className			="product-description-container"
					// style={{
					// 	display: 				'flex',

					// 	// width: 					'100%',
					// 	// width: 					'60%',
					// 	width: 					'50%',  

					// 	flexDirection:			'column', 		// Verander van 'row' naar 'column' zodat de img en ul onder elkaar komen
					// 	// alignItems: 			'center',
					// 	alignItems: 			'flex-end',

					// 	padding:				'1rem',


					// 	// Media query voor kleine schermen
					// 	'@media (max-width: 768px)': {
					// 		width: 					'100%',      // Volledige breedte op kleine schermen
					// 	},


					// 	// border: 				'1px solid #00FFFF',
					// 	backgroundColor: 						'var(--secondary-color)',
					// }}
				>

					{/* 
						image
					*/}
					<img 					
						src					= { course.storeImageUrl }
						alt					= ""
						style				= {{
												display: 				'block',

												// width: 					'60%',
												width: 					'80%',
												
												// margin: 				'0 auto',
												margin: 				'0',
												// padding:				'2rem',

												borderRadius: 			'20px',

												textAlign: 				'left'
												// [ ] test border
												// border: 				'1px solid #00FF00',
											}}
					>
					</img>

					

					{/* 
						Course info
					*/}
					<div
						style={{
							
							// width: 						'60%', 			// Zelfde breedte als de afbeelding
							width: 						'80%', 			// Zelfde breedte als de afbeelding

							// margin: 					'0 auto',
							margin: 					'0',
							color: 						'var(--quaternary-color)',
							textAlign: 					'left', 		// Tekst links uitlijnen
							paddingTop: 				'1rem',
						}}
					>
						{/* De bron is gecontroleerd, dus dangerouslySetInnerHTML kan hier geen kwaad */}
						<p
								dangerouslySetInnerHTML={{
								__html: course.coursePageText
							}}
						>
						</p>

						

					</div>


					<div

						style={{
							// width: 						'60%', 			// Zelfde breedte als de afbeelding
							width: 						'80%', 			// Zelfde breedte als de afbeelding
							
							// margin: 					'0 auto', 		// Zelfde uitlijning als de afbeelding
							margin: 					'0',

							color: 						'var(--quaternary-color)',
							textAlign: 					'left',
							paddingTop: 				'1rem',
							// padding: 	'1rem',
						}}
					>

						{/* <p> { course.lessonTitleArray.length } Lessons </p> */}

						<p>Course content:</p>
						{/* <ul
							style = {{
								textAlign: 'left'
							}}
						> */}

							{course.lessonTitleArray.map((title, index) => (
								<div
										key				= {index}
								>
									<p 
										style			= {{
																// color:					'var(--tertiary-color)',
																color: 						'var(--quaternary-color)',
																textAlign: 					'left',

																marginLeft:					'1rem'
															}}
									>
									{ title }
									</p>
								</div>
							))}
						{/* </ul> */}
					</div>
				</div>



				{/* 
					Order container
				*/}
				<div
					className		= "product-order-container"

					// style		= {{
					// 				display: 				'block',
					// 				// width: 					'100%', 		// Optioneel: past de breedte van de afbeelding aan
					// 				width: 					'30%',
					// 				// width: 					'50%', 		
					// 				// margin: 				'0 auto',
					// 				marginRight:			'2rem',
					// 				padding:				'1rem',
					// 				borderRadius: 			'20px',
					// 				// backgroundColor: 						'var(--quaternary-color)',
					// 				// Media query voor kleine schermen
					// 				'@media (max-width: 768px)': {
					// 					width: 					'100%',      // Volledige breedte op kleine schermen
					// 					marginRight: 			'0',   // Verwijder de rechter marge op kleine schermen
					// 				},
					// 				// [ ] test border
					// 				border: 				'1px solid #FF0000',
					// 			}}
				>

					{/* 
						Order panel
					*/}
					<div 
						style			= {{ 
											display: 					'flex', 

											// width: 						'100%',
											width: 						'70%',

											flexDirection:				'column',
											justifyContent:				'flex-start',
											alignItems: 				'center', 
											// alignItems: 				'flex-start', 
											
											// marginLeft: 					'4rem',
											// marginRight: 				'4rem',
											// marginLeft:					'8px',
											padding:					'2rem',
											
											textAlign: 					'center',              // Zorg dat tekst en inhoud binnen het panel gecentreerd zijn
									        margin: 					'0 auto',              // Zorg dat het panel zelf gecentreerd blijft in de container
											
											
											borderRadius: 				'20px',

											backgroundColor:		'var(--tertiary-color)',
											color:					'var(--primary-color)',

											// border: 				'1px solid #FF0000',
										}}
					>

						<h3>Complete order</h3>
		

						<div
							style			= {{ 
												display: 					'flex', 
												// flexDirection:				'row',
												flexDirection:				'column',
												// marginTop: 					'4rem',
												marginTop: 					'2rem',
											}}
							
						>

							{course.prices.map((price, index) => (
								<label 
									key				={index} 
									style			= {{ 
														// margin: '4px',
														// margin: '1rem', 
														marginRight: 	'2rem', 
														marginBottom: 	'1rem', 
														display: 		'block',

														color:			'var(--primary-color)',
													}}
								>
									<input
										type			="radio"
										name			="price"

										value			={index}
										checked			={selectedPriceIndex === index}
										onChange		={() => {
															console.log(index);
															// selectPriceRef.current = index;
															setSelectedPriceIndex(index);
														}}

										style			={{
															marginRight: 	'12px',

															cursor: 		'pointer'
														}}
									/>
									{price}
								
									{/* {index === 0 && (
										<p>
											or
										</p>
									)} */}

								</label>

							))}
						
						</div>


						<div
							// onClick			= {() => buyProduct(course.priceId, null)}
							onClick			= {() => buyProduct()}

							style			= {{ 
												display: 				'flex', 
												alignItems: 			'center', 
												
												height: 				'40px',
												
												
												// marginRight: 		'10px',
												marginTop: 				'2rem',
												marginBottom: 			'1rem',

												padding: 				'1rem',

												// color: 					'var(--secondary-color)',
												// color: 					'var(--primary-color)',
												color: 					'var(--secondary-color)',
												// backgroundColor: 		'var(--secondary-color)',
												// backgroundColor: 		'var(--tertiary-color)',
												backgroundColor: 		'var(--primary-color)',
												// border:					'2px solid var(--secondary-color)',
												borderRadius: 			'8px',

												cursor: 				'pointer',
											}}
						>
							<p
								style			= {{ fontWeight: 			'bold' }}
							>Buy course</p>
						</div>

						
						
					</div>

				</div>
			</div>
		</div>
	);
}

import VideoPlayer 			from '../components/VideoPlayer';
import 
	React, 
	{ 
		useState, 
		useEffect 
	} 						from 'react';
// import { 
// 	Link, 
// 	useMatch, 
// 	useResolvedPath 
// 	} 						from "react-router-dom"
// import { auth } 			from "../utils/firebase";
// import { useAuthState } from "react-firebase-hooks/auth";
import { useAuth } 			from '../context/AuthContext';

import {
	// BrowserRouter, 
	// Route, 
	// Routes, 
	useNavigate, 
	useLocation
} 							from 'react-router-dom';
// import { 
// 	getFunctions, 
// 	httpsCallable 
// } 							from "firebase/functions";
// import { app } 				from '../utils/firebase';
import { ClipLoader } 		from 'react-spinners';

import { 
	ref, 
	listAll,
	getDownloadURL 
} 								from 'firebase/storage';
import { storage } 				from '../utils/firebase'; // Importeer de storage-configuratie



export default function Lessons() {

	// user stuff and routing
	/***
		(sep 24)
			- nieuwe methode (AuthContext) maakt gebruik van persistence waardoor gebruiker na page refresh toch ingelogd blijft
	*/
	// const [user, loading] 						= useAuthState(auth);
	const { user, loading } 					= useAuth();

	const navigate 								= useNavigate();
	const location 								= useLocation();

	const [pageLoading, setPageLoading] 		= useState(true);

	// let courseId								= location.state.courseId;
	let course									= location.state.course;

	console.log(course);

	let courseId								= course.id;
	let storageFolderPath						= course.storageFolderPath;

	const [lessons, setLessons] 				= useState([]);
	const [activeLesson, setActiveLesson]		= useState(0);

	const [downloadLinks, setDownloadLinks] 	= useState([]);



	const addDownloadLink = (name, url) => {
		setDownloadLinks([...downloadLinks, { name, url }]);
	};


	const goToLesson = async (index) =>{

		console.log(index, lessons[index]);
		
		/***
		
			Fetch bladmuziek
		


				(okt 24)

					[ ] path dynamisch maken!!
						[ ] course name (= folder root)
						[ ] ..


					[ ] download links toevoegen aan pagina



			
					[ ] losse folders nog vullen in FireBase storage

						[ ] Jimi Hendrix course
== > 	(6 okt 24)
							[ ] naam folder klopt niet: is nu Jimmy ipv Jimi
								- issue: alle video's staan er al in en zijn stuk voor stuk gereft in firestore!!!
								[ ] ik kan voor nu ff een aparte 'folderPath' property toevoegen aan FireStore 


							[ ] folders maken
								[ ] bladmuziek
								[ ] losse folder per les (bijv '01')


						[ ] beginners course

							[ ] folders maken
								[ ] bladmuziek
								[ ] losse folder per les (bijv '01')
		*/
		var filePath 		= storageFolderPath+'/bladmuziek/';
		var lessonNumber 	= index + 1;
		filePath 			= filePath +  lessonNumber.toString().padStart(2, '0');

		// console.log(path_TEST);

		const downloadConfigs 			= await fetchAllImages(filePath);


		// urls.forEach(link => {
		// 	// addDownloadLink('test2', link);
		// 	downloadConfigs.push({ 
		// 		name: link, 
		// 		link: link 
		// 		})
		// });

		setDownloadLinks( downloadConfigs );


		// ..
		// const downloadList = document.getElementById('downloadList');
		// // Eerst de lijst leegmaken
    	// downloadList.innerHTML = '';
		// // Voor elke downloadlink een lijstitem aanmaken
        // urls.forEach(link => {
        //     const listItem 			= document.createElement('li');
        //     const anchor 			= document.createElement('a');
        //     anchor.href 			= link.url;
        //     // anchor.textContent = link.name;
		// 	anchor.textContent 		= 'test';
		// 	anchor.download 		= ''; // Zorgt ervoor dat het bestand wordt gedownload
        //     listItem.appendChild(anchor);
        //     downloadList.appendChild(listItem);
        // });


		// [ ] 	setActiveLesson(..)
		setActiveLesson( index );

	}



	// Functie om alle URL's in een map op te halen
	const fetchAllImages = async (folderPath) => {

		const folderRef 			= ref(storage, folderPath);
		// const urls = [];
		const downloadConfigs		= [];
	
		try {
			// Haal de lijst met bestanden op
			const result = await listAll(folderRef);

			// Doorloop elk bestand in de lijst en haal de downloadbare URL op
			for (const itemRef of result.items) {
				
				// console.log(itemRef);

				const url = await getDownloadURL(itemRef);
				const fileName = itemRef.name;
				
				// urls.push(url);
				downloadConfigs.push({ name: fileName, url: url });
			}

			console.log(downloadConfigs);

			return downloadConfigs; // Geeft een array met downloadbare URLs terug

		} catch (error) {
			console.error("Fout bij ophalen van mapinhoud:", error);
			return [];
		}
	};



	useEffect(
		() => {

			const fetchLessons = async () => {

				try{
				
					if( user ){

						const idToken 		= await user.getIdToken();

						const headers 		= new Headers();
						headers.append('Content-Type', 'application/json');
						headers.append('Authorization', `Bearer ${idToken}`);

						// Stuur een HTTP-verzoek naar de Firebase Cloud Function
						const response = await fetch(

							'https://getcourselessonsforuser-zso6kje7mq-uc.a.run.app', 
						
							{
								method: 	'POST',
								headers: 	headers,
								body: 		JSON.stringify({courseId: courseId})
							}
						);


						if (response.ok) {
							// Parse het antwoord en stel myCourses in met de ontvangen gegevens
							const data = await response.json();

							if(data.length > 0){
								
								setLessons(data[0].data.lessons);
							}
							else{
								// [ ] todo: melding indien geen courses (of redirect?)
							}
						} 
						else {
							console.error('Fout bij het ophalen van lessen:', response.statusText);
						}
					}
				}
				catch (error) {
					console.error('1: Fout bij het ophalen van lessen:', error);
				}

				setPageLoading(false);
			};


			// Roep de functie aan bij het laden van de pagina
			fetchLessons();
		}, 
		[]					// Lege afhankelijkheidsarray zorgt ervoor dat deze effect slechts eenmaal wordt uitgevoerd bij het mounten van de component
	); 




	// only load page if user is logged in
	if( 
		user
	){

		return (

			<>
			{ 
				pageLoading 
				
				? 
				
				(
					<div className="loading-indicator">
				
						<br></br>

						<ClipLoader 
							// color			="#36D7B7" 
							color			= 'var(--tertiary-color)'
							loading			= {pageLoading} 
							size			= {50} 
						/>
					</div>
				) 
				
				: 
				
				(
					<div
						style={{ 
							// padding:				40,
							padding:				20,
							// color: 					'#950101'
							color:					'var(--secondary-color)'
						}}
					>

						{/******************************************* 
						
							Course title 
						
						********************************************/}
						{ 
							lessons.length > 0 &&
							<h1> { lessons[activeLesson].title } </h1>
						}


						{/******************************************* 
						
							Container (for player and playlist) 
						
						*******************************************/}
						<div
							className = "video-playlist-container"
						>

							{/******************************************* 
							
								Video player 
								
							*******************************************/}
							{ lessons.length > 0 &&
								<div>
									<VideoPlayer 
										url = {lessons[activeLesson].url}
									/>
								</div>
							}



							{/*******************************************

								Playlist 
							
							*******************************************/}
							<div
								className="playlist-container"
							>

								<ul
									style = {{
										textAlign: 'left'
									}}
								>
									{
										lessons.map( ( lesson, index ) => 

											<div
												key				= {index}
											>

												{/* 

													[ ] we willen eigenlijk nog aangeven welke les actief is (jan 24)

												*/}
												<h3 
													style			= {{
																		color:					'var(--tertiary-color)',
																		cursor: 				'pointer'
																	}}

													onClick			= { ()=> {goToLesson(index)}}
												>
													{ index + 1 }  -  { lesson.title }
												</h3>

												{ activeLesson == index  &&
													
													downloadLinks.map((link, index) => (
														<li key={index} style={{ margin: '10px 0', listStyleType: 'none' }}>
															<a 
																href		= {link.url} 
																style		= {{ 
																				color: 				'var(--secondary-color)', 
																				textDecoration: 	'none', 
																				// fontWeight: 		'bold' 
																				margin: 			'24px'
																			}}
																download
															>
																{link.name}
															</a>
														</li>
													)) 
												}
												
											</div>
										)
									}
								</ul>
							</div>
						</div>
					</div>
				)
			}
			</>
		)
	}
	/***
		redirect if user is not logged in
	*/
  	else{
		navigate('/');
	}
	
}


import React 					from "react"
import { 
	Link, 
	useMatch, 
	useResolvedPath, 
	useLocation 
} 								from "react-router-dom"

import { 
	auth 
} 								from "../utils/firebase";
import { 
	useAuthState 
} 								from "react-firebase-hooks/auth";

import ProductList 				from "../components/ProductList";
// import Carousel 				from "../components/Carousel";



export default function Home() {
	
	const [user, loading] = useAuthState(auth);



	return (

		<div
			className					= "home-container"
		>

		
			{/* Container voor image en welkom */}
			<div
				className					= "cover-container"
			>
				
						<img 
							className			= "image-container"

							src					= 'cover-2-medium.png'
							alt					= ""
						/>
					
					{

						user ? 
						
							<div
								className 			= "welcome-container"
							>
								<h1>Welcome <br></br> {user.displayName}</h1>
							</div>

							: 
							
							<div
								className 			= "promo-container"
							>

								<h1
									style 					= {{ 
																paddingBottom:				'10%'
															}}
								>
									GUITAR COURSES FOR ALL LEVELS
								</h1>
								
								<div
									style 					= {{ 
																paddingBottom:			'10%',
																color:					'var(--quaternary-color)',
																fontFamily:				"Roboto, sans-serif", 
																// fontFamily: 			"Open Sans, sans-serif"
																// fontFamily: 			"Merriweather Sans, sans-serif"
																// [ ] test border
																// border: 				'1px solid #0000FF',
															}} 
								>
									<p> 
										Improve your playing together with your teacher Thom Helwig (Guitar Pilgrim)
									</p>
									{/* <p> 
										Discover tips and techniques to improve your guitar playing 
									</p>
									<p>
										through your teacher, Thom Helwig. 
									</p>
									<p>
										Many high quality instruction video lessons and more...
									</p> */}
								</div>
								
								<br>
								</br>

								{/* 
									[ ] nog ff navragen bij Thomas: deze kon weg, maar wil ie geen andere knop voor signup? (okt 24)

								<Link 
									to							= "/signup" 
									className					= "join-button"
								>
									Start playing!
								</Link> */}
							</div> 
					}
			</div>


			{/* 
				ProductList 
			*/}
			<div
				style 			= {{ 
									paddingTop:			'1rem',
									// paddingBottom:		'4rem',
									// paddingLeft:		'4rem',
									// paddingRight:		'4rem',	

									// [ ] test border
									// border: 			'1px solid #FF00FF',
								}}
			>

				<ProductList>
				</ProductList>

			</div>
		</div>
	)
}

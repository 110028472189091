
/***

	NavBar - variant: contrast
	--------------------------
	
		
		- opaque
		- contrasting colors 
		- broad header

*/

import { Link, useMatch, useResolvedPath, useLocation } from "react-router-dom"
import { auth } from "../utils/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

import CustomLink 				from "../components/CustomLink"; 



export default function Navbar() {

	const [user, loading] = useAuthState(auth);

	const location = useLocation();

	// Controleer of de huidige locatie de homepage is
	const isHomePage = location.pathname === '/';



  	return (
		<>
			<nav 
				className="nav"
				// className = {`nav ${isHomePage ? 'large' : 'small'}`}
			>
				{/* <h1 className={`site-title ${isHomePage ? 'large' : 'small'}`}>
        Mijn Site
      </h1> */}
				<Link 
					to="/" 
					// className="site-title"
					className = {`site-title ${isHomePage ? 'large' : 'small'}`}
				>
					Guitar Pilgrim
				</Link>

				<ul>
					<CustomLink to="/">				Home		</CustomLink>
					
					<CustomLink to="/courses">		Courses		</CustomLink>

					{/* {
						user ?
							<CustomLink to="/Lessons">Lessons</CustomLink>
							: null
					} */}
					
					{
						user ?
							<CustomLink to="/MyCourses">My courses</CustomLink>
							: null
					}
					
					{/* <CustomLink to="/pricing">Pricing</CustomLink> */}
					
					{/* <CustomLink to="/about">About</CustomLink> */}
					
					{ 
						user ? 
							<NavButton to="/" onClick={() => auth.signOut()}>Logout</NavButton>
							: <CustomLink to="/login">Login</CustomLink>
					}
					
					{ 
						! user ? 
							<CustomLink to="/Signup">Sign up</CustomLink>
							: null
					}
					
					{/* <CustomLink to="/test">Test</CustomLink> */}
				</ul>
			</nav>
		</>
  	)
}



/***  
	[ ] nu maar ff aparte component van gemaakt want deze werd actief (waarschijnlijk omdat ie ok naar Home verwees) (dec 23)
*/
function NavButton({ to, children, ...props }) {

  	// const resolvedPath = useResolvedPath(to)
	const isActive = false

	return (
		<li 
			className={isActive ? "active" : ""}
		>
			<Link to={to} {...props}>
				{children}
			</Link>
		</li>
	)
}



// function CustomLink({ to, children, ...props }) {
//   	const resolvedPath = useResolvedPath(to)
// 	const isActive = useMatch({ path: resolvedPath.pathname, end: true })
// 	return (
// 		<li 
// 			className={isActive ? "active" : ""}
// 		>
// 			<Link to={to} {...props}>
// 				{children}
// 			</Link>
// 		</li>
// 	)
// }
import 
	React, { 
		useEffect, 
		useState, 
		useRef 
	} 						from 'react';
import { useNavigate } 		from 'react-router-dom';

import { 
	getFunctions, 
	httpsCallable 
} 							from "firebase/functions";
import { 
	app, 
	auth, 
	db 
} 							from '../utils/firebase';
import { useAuthState } 	from "react-firebase-hooks/auth";
import { 
	collection, 
	getDocs,
	query, 
	where 
} 							from 'firebase/firestore';

import Card from "./Card";


export default function ProductList() {
	
	const functions 						= getFunctions(app, "us-central1");
	const [user, loading] 					= useAuthState(auth);
	const navigate 							= useNavigate();


	// const courses 							= [
	// 											{
	// 												title				: "Beginners Course",
	// 												imageSrc 			: "https://firebasestorage.googleapis.com/v0/b/guitarpilgrimacademy.appspot.com/o/images%2Fimage%20beginners%20medium.jpg?alt=media&token=d51f3abe-5448-4601-84e4-81e12cfed0f8",
	// 												priceId				: "price_1PxZ5RCx3l4c0NUQ3Gw3hqWx",
	// 											},
	// 											{
	// 												title				: "Jimmy Hendrix Course",
	// 												imageSrc 			: "https://firebasestorage.googleapis.com/v0/b/guitarpilgrimacademy.appspot.com/o/images%2Fimage%20hendrix%20medium.jpg?alt=media&token=5b128380-1fc6-4ae6-87a0-db21484830c2",
	// 												priceId				: "price_1PxYzhCx3l4c0NUQGvI3OjS0"
	// 											},
	// 											// Voeg hier meer cursussen toe
	// 										];


	/***
	
		Fetch courses
	
	*/
	const [courses, setCourses] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				// Verwijs naar de collectie die je wilt ophalen
				const querySnapshot = await getDocs(collection(db, 'courses'));

				// Zet de documenten om naar een array van objecten
				const docsArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
				console.log(docsArray);

				// dev dev
				docsArray.forEach( doc => {

					if(doc.lessonTitles){
						
						const lessons = doc.lessonTitles.split(";");
						console.log(lessons.length, lessons);
						doc.lessonTitleArray = lessons;
					}
				})

				// Update de state met de opgehaalde documenten
				setCourses(docsArray);
			} catch (error) {
				console.error("Error fetching courses: ", error);
			}
			};

			fetchData();
		}, 
		[]
	);

	// useEffect(() => {
	// 	const fetchData = async () => {
	// 		try {
	// 			// Verwijs naar de collectie die je wilt ophalen
	// 			const courseQuerySnapshot = await getDocs(collection(db, 'courses'));
	// 			// Map door alle courses heen om ze als array op te slaan
	// 			const coursesArray = await Promise.all(courseQuerySnapshot.docs.map(async (courseDoc) => {
	// 				const courseData = { id: courseDoc.id, ...courseDoc.data() };
	// 				// Voor elke course: haal de bijbehorende lessen op uit de 'lessonCollection'
	// 				const lessonQuery = query(
	// 					collection(db, 'lessonCollection'), 
	// 					where('courseId', '==', courseDoc.id) // Filter op courseId
	// 				);
	// 				const lessonQuerySnapshot = await getDocs(lessonQuery);
	// 				// We gaan ervan uit dat elk document een 'lessons' array heeft
	// 				const lessonsData = lessonQuerySnapshot.docs.map((lessonDoc) => {
	// 					// Filter alleen de 'title' uit de 'lessons' array (maps)
	// 					const lessonsWithTitleOnly = lessonDoc.data().lessons.map((lesson) => ({
	// 						title: lesson.title // Haal alleen de 'title' op, geen 'url'
	// 					}));
	// 					return lessonsWithTitleOnly;
	// 				});
	// 				// Voeg de gefilterde lessen toe aan de course data
	// 				return {
	// 					...courseData,
	// 					lessons: lessonsData.flat() // Voeg alle lessen (title-only) toe
	// 				};
	// 			}));
	// 			console.log(coursesArray);
	// 			// Update de state met de courses inclusief de gefilterde lessons
	// 			setCourses(coursesArray);
	// 		} catch (error) {
	// 			console.error("Error fetching courses: ", error);
	// 		}
	// 		};
	// 		fetchData();
	// 	}, 
	// 	[]
	// );



	/***

		(aug 24)

			[ ] indien nog geen account --> gebruiker omleiden naar sign-up pagina

			[ ] ff kijken welke functionaliteit hier hoort, en wat in Store.js



		(jan 24)

			[ ] dynamisch maken (zie MyCourseList.js)
	* /
	const buyProduct = async (priceId, paymentMethodTypes, index) => {
		// Check of gebruiker al een account heeft
		if( ! user ){
			// [ ] redirect naar account 
			// [ ] met een object dat onthoudt dat we hier vandaan kwamen. Zodat we de betaling af kunnen maken
			console.log('No account --> redirect to sign-up page or login');
			// voorbeeldje:
			// navigate(`/lessons`,{state:{courseId:courseId}});
			navigate(`/signup`/* , [ ] object toevoegen voor afmaken bestelling * /);
			return;
		}
		// Door naar Stripe checkout
		try {
			const checkout = httpsCallable( functions, 'checkout' );
			checkout( 
				{
					items: 					[
												{
													"id": 			priceId,
													"quantity": 	1
												}
											],
					paymentMethodTypes:		['card']
				}
			)
			.then((response) => {
				return JSON.parse(response.data);
			})
			.then((response) => {
				if(response.url) {
					window.location.assign(response.url); // Forwarding user to Stripe
				}
			});
		}
		catch(error){
			console.log(error);
		}
	}
	*/


	
	const handleProductClick = (id) => {
		navigate(`/product/${id}`);
	};



	const selectPriceRefs = useRef([]);



	return (
		<>
			<div className		= "grid-container">

				{
					courses.map(
						(course, index) => {


							return (
							<div 
								// key				= {course.priceId} 
								key				= {course.name} 
								className		= "tile-wrapper"
							>
								<div className		= "tile">

									<Card
										title			= {course.name}
										body			= {course.storeDescription}
										imageSrc		= {course.storeImageUrl}


										/***
										
== > 	(29 sep 24)

			[ ] prijs opties:

					[ ] werkelijke bedragen bij Stripe priceId ophalen

						[/] voor nu gewoon maar ff hard in fireStore zetten
							[-] optie a) prices: [ { prideId: , price: .. }]
							[/] optie b) 
								priceIds: [ prideId1, .. ]
								prices: [ '49 euro', '49 usd' ]

						[ ] styling etc


					[ ] verschillende opties koppelen in dropdown

										
										
										*/
										// onClick			= {() => buyProduct(course.priceId)}
										onClick			= { () => navigate(`/product/${course.priceId}`, { state: { course } }) }
									>

										{/* Child componenten worden hier geplaatst */}
										<div 
											style			= {{ 
																display: 					'flex', 
																// justifyContent: 			'space-between', 
																// justifyContent: 			'center', 
																justifyContent:				'flex-start',
																alignItems: 				'center', 
																// marginTop: 					'32px',
																marginTop: 					'10px',
																marginLeft:					'8px',
															}}
										>	
											{/* <p
											
											>
												Extra tekst of elementen kunnen hier komen.
											</p> */}
											{/* <div
												style			= {{ 
																	// marginRight: 		'10px',
																	display: 			'flex', 
																	alignItems: 		'center', 
																	
																	height: 			'40px',
																	padding: 			'6px',

																	// border: 			'1px solid #950101',
																	border: 			'1px solid var(--secondary-color)',
																	borderRadius: 		'4px'
																}}
											>
												<p>buy course</p>
											</div> */}

											{/* <select 
												ref				= { 
																	(el) => ( selectPriceRefs.current[index] = el ) 
																}
												style			= {{ 
																	marginLeft: 		'4px',
																	height: 			'40px',
																	padding: 			'6px',
																	border:				'none',
																	color:				'var(--secondary-color)',
																	backgroundColor:	'var(--primary-color)',
																	borderRadius: 		'4px'
																}}
											>
												<option 
													value			= { course.priceIds[0] }
												>	
																	{ course.prices[0] }	
												</option>
												
												<option 
													value			= { course.priceIds[1] }
												>		
																	{ course.prices[1] }	
												</option>
											</select> */}
											<div
												style			= {{ 
																	// marginRight: 		'10px',
																	display: 			'flex', 
																	flexDirection:		'row',
																	alignItems: 		'center', 
																	
																	height: 			'60px',
																	padding: 			'6px',

																	// border: 			'1px solid var(--secondary-color)',
																	// borderRadius: 		'4px'
																}}
											>
												<div
													style			= {{ marginRight: 		'10px' }}
												>
												{ course.prices[0] }
												</div>
												
												<div
													style			= {{ marginRight: 		'10px' }}
												>
													/
												</div>
												
												<div>
													{ course.prices[1] }
												</div>
											</div> 
											
											
										</div>
									
									</Card>
								</div>
							</div>
							)
						}
					)
				}
			</div>
		</>
	)
}